import { Order } from '@medusajs/medusa';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { QUERY_KEYS } from '../services/queryKeys';

export const useDealerOrder = () => {
  const queryClient = useQueryClient();

  const approveOrder = async (order: Order) => {
    const response = await axios.put(
      `/store/dealers/orders/${order.id}/approve`
    );

    queryClient.invalidateQueries([
      QUERY_KEYS.order.get,
      'display_id',
      order.display_id.toString(),
    ]);

    return response.data;
  };

  const rejectOrder = async (order: Order, reason: string) => {
    const response = await axios.put(
      `/store/dealers/orders/${order.id}/reject`,
      { reason }
    );

    queryClient.invalidateQueries([
      QUERY_KEYS.order.get,
      'display_id',
      order.display_id.toString(),
    ]);

    return response.data;
  };

  return { approveOrder, rejectOrder };
};
