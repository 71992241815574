import { Order } from '@medusajs/medusa';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import { useDealerOrder } from '../../hooks/use-dealer-order';
import Checkmark from '../../icons/checkmark';
import Cross from '../../icons/cross';
import OrderDetailHeader, {
  OrderDetailHeaderProps,
} from '../orders/detail/OrderDetailHeader';
import notification from '../utility/notification';
import RejectOrderModal from './RejectOrderModal';

const DealerOrderDetailHeader = (props: OrderDetailHeaderProps) => {
  return (
    <OrderDetailHeader
      {...props}
      headerAction={<ReviewAction order={props.order} />}
    />
  );
};

const ReviewAction = ({ order }: { order?: Order }) => {
  const { approveOrder } = useDealerOrder();

  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!order || !!order.reviewed_by_dealer_staff) {
    return null;
  }

  const handleApprove = async () => {
    try {
      setLoading(true);

      await approveOrder(order);

      notification({
        key: 'approve-dealer-order-success',
        type: 'success',
        message: 'อนุมัติคำสั่งซื้อสำเร็จ',
      });

      setOpenApprovalModal(false);
    } catch (error) {
      notification({
        key: 'approve-dealer-order-error',
        type: 'error',
        message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        onClick={() => setOpenRejectModal(true)}
        className="mr-2 !inline-flex items-center"
        danger
        icon={<Cross className="mr-2 inline-block" />}
      >
        ยกเลิก
      </Button>
      <Button
        onClick={() => setOpenApprovalModal(true)}
        className="!inline-flex items-center"
        type="primary"
        icon={<Checkmark className="mr-2 inline-block" />}
      >
        อนุมัติ
      </Button>
      <RejectOrderModal
        open={openRejectModal}
        onClose={() => setOpenRejectModal(false)}
        order={order}
      />
      <Modal
        visible={openApprovalModal}
        title="อนุมัติคำสั่งซื้อ"
        okText="ยืนยัน"
        cancelText="ยกเลิก"
        confirmLoading={loading}
        onOk={handleApprove}
        onCancel={() => setOpenApprovalModal(false)}
      >
        ยืนยันการอนุมัติคำสั่งซื้อ
      </Modal>
    </div>
  );
};

export default DealerOrderDetailHeader;
