import { Order } from '@medusajs/medusa';
import { Form, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';

import { useDealerOrder } from '../../hooks/use-dealer-order';
import notification from '../utility/notification';

interface FormValue {
  reason: string;
}

const RejectOrderModal = ({
  open,
  onClose,
  order,
}: {
  open: boolean;
  onClose: () => void;
  order: Order;
}) => {
  const { rejectOrder } = useDealerOrder();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<FormValue>();

  const handleReject = async (payload: FormValue) => {
    try {
      setLoading(true);

      await rejectOrder(order, payload.reason);

      notification({
        key: 'reject-dealer-order-success',
        type: 'success',
        message: 'ยกเลิกคําสั่งซื้อสําเร็จ',
      });

      onClose();
    } catch (error) {
      notification({
        key: 'reject-dealer-order-error',
        type: 'error',
        message: 'พบข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      visible={open}
      title="ยกเลิกคำสั่งซื้อ"
      okButtonProps={{ danger: true }}
      okText="ยกเลิกคำสั่งซื้อ"
      cancelText="ยังก่อน"
      confirmLoading={loading}
      onOk={form.submit}
      onCancel={onClose}
      width={628}
    >
      <Form
        form={form}
        onFinish={handleReject}
        layout="vertical"
        autoComplete="off"
        disabled={loading}
      >
        <Form.Item
          name="reason"
          label={<div className="font-medium">สาเหตุ</div>}
          required
          rules={[{ required: true, message: 'กรุณากรอกเหตุผล' }]}
        >
          <TextArea placeholder="สาเหตุการยกเลิกคำสั่งซื้อ" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RejectOrderModal;
