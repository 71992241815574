import { RouteComponentProps } from '@reach/router';
import React from 'react';

import { formatDisplayOrderId } from '../../helpers/order.helper';
import { useOrder } from '../../hooks/use-order';
import Breadcrumb from '../Breadcrumb';
import Section from '../Section';
import AccountLayout from '../account/AccountLayout';
import MainLayout from '../layout/MainLayout';
import OrderDeliveryAddress from '../orders/detail/OrderDeliveryAddress';
import OrderItemList from '../orders/detail/OrderItemList';
import DealerOrderDetailHeader from './DealerOrderDetailHeader';

const DealerOrderDetailPage = ({
  handle,
}: RouteComponentProps<{ handle: string }>) => {
  const { order, isOrderLoading } = useOrder({ displayId: handle });

  const displayOrderId = formatDisplayOrderId(handle!);

  return (
    <MainLayout>
      <Breadcrumb
        items={[
          {
            title: 'คำสั่งซื้อดีเลอร์',
            link: '/dealer-orders',
          },
          {
            title: displayOrderId,
            link: `/dealer-orders/${handle}`,
          },
        ]}
        title={`คำสั่งซื้อดีเลอร์ ${displayOrderId}`}
      />
      <Section className="py-6">
        <AccountLayout>
          <div className="flex flex-col gap-3">
            <DealerOrderDetailHeader loading={isOrderLoading} order={order} />
            <OrderDeliveryAddress shippingAddress={order?.shipping_address} />
            <OrderItemList loading={isOrderLoading} order={order} />
          </div>
        </AccountLayout>
      </Section>
    </MainLayout>
  );
};

export default DealerOrderDetailPage;
