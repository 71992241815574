import React from 'react';

const Checkmark = ({ className }: { className?: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Frame">
        <g id="Vector">
          <path
            d="M17.25 6.75L6.75 17.25L17.25 6.75Z"
            fill="#F5222D"
            style={{
              fill: '#F5222D',
              fillOpacity: 1,
            }}
          />
          <path
            d="M6.75 6.75L17.25 17.25L6.75 6.75Z"
            fill="#F5222D"
            style={{
              fill: '#F5222D',
              fillOpacity: 1,
            }}
          />
          <path
            d="M17.25 6.75L6.75 17.25M6.75 6.75L17.25 17.25"
            stroke="#F5222D"
            style={{
              stroke: '#F5222D',
              strokeOpacity: 1,
            }}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default Checkmark;
